.dashboard-screen {
  height: 100vh;

  .header {
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.02), 0 1px 0 rgba(0, 0, 0, 0.02);
    border-bottom: 1px solid #f1f1f1;
    display: flex;
    justify-content: space-between;
    padding: 0 15px;

    .left-flex {
      display: flex;

      .trigger {
        color: @primary-color;
        text-decoration: none;
        background-color: transparent;
        outline: none;
        cursor: pointer;
        transition: color 0.3s;

        .menu-bar {
          transform: rotate(90deg);
          margin-right: 1rem;
        }
      }
    }

    .user-avatar {
      .ant-avatar {
        background-color: #bf3b2c;
        color: #fff;
      }
    }
  }

  .main-container {
    padding: 10px 24px 24px;

    @media only screen and (max-width: 1024px) {
      padding: 10px;
    }
  }
}
