.layout-demo .row {
  margin-bottom: 0.5rem;
}
.layout-demo .col {
  background-color: #e9ecef;
  border: 1px solid rgba(0, 0, 0, 0.05);
  padding: 1rem;
}
.layout-demo [class*='col-'] {
  background-color: #e9ecef;
  border: 1px solid rgba(0, 0, 0, 0.05);
  padding: 1rem;
}
.button-demo .btn {
  min-width: 7rem;
}
.ant-layout-sider-children {
  background: #fff;
  overflow: hidden;
  flex: 1 1 auto;
  flex-direction: column;
  display: flex;
}
[id^='components-button-demo'] div:not(.ant-btn-group) > .ant-btn,
[id^='components-button-demo'] span:not(.ant-btn-group) > .ant-btn,
[id^='components-button-demo'] .ant-btn-group {
  margin-right: 8px;
  margin-bottom: 12px;
}

[id^='components-button-demo'] p {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
}

[id^='components-icon-demo'] .icons-list > .anticon {
  margin-right: 6px;
  font-size: 24px;
}

[id^='components-affix-demo'] .scrollable-container {
  height: 100px;
  overflow-y: scroll;
}
[id^='components-affix-demo'] .background {
  padding-top: 60px;
  height: 300px;
  background-image: url('https://zos.alipayobjects.com/rmsportal/RmjwQiJorKyobvI.jpg');
}
[id^='components-pagination-demo'] .ant-pagination:not(:last-child) {
  margin-bottom: 24px;
}

[id^='components-form-demo-advanced-search'] .ant-form {
  max-width: none;
}
[id^='components-form-demo-advanced-search'] .search-result-list {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}
[id^='components-form-demo-global-state'] .language-bash {
  max-width: 400px;
  border-radius: 6px;
  margin-top: 24px;
}

[id^='components-switch-demo'] .ant-switch {
  margin-bottom: 8px;
}

[id^='components-slider-demo'] h4 {
  margin: 0 0 16px;
}
[id^='components-slider-demo'] .ant-slider-with-marks {
  margin-bottom: 44px;
}
[id^='components-avatar-demo-basic'] .ant-avatar {
  margin-bottom: 16px;
  margin-right: 16px;
}
[id^='components-badge-demo'] .ant-badge:not(.ant-badge-not-a-wrapper) {
  margin-right: 20px;
}
[id^='components-badge-demo'] .anticon-notification {
  width: 16px;
  height: 16px;
  line-height: 16px;
  font-size: 16px;
}
[id^='components-badge-demo'] .ant-badge-not-a-wrapper:not(.ant-badge-status) {
  margin-right: 8px;
}
.head-example {
  width: 42px;
  height: 42px;
  border-radius: 4px;
  background: #eee;
  display: inline-block;
  vertical-align: middle;
}
[id^='components-popover-demo-placement'] .ant-btn {
  width: 70px;
  text-align: center;
  padding: 0;
}
[id^='components-tooltip-demo-placement'] .ant-btn {
  width: 70px;
  text-align: center;
  padding: 0;
}
[id^='components-tabs-demo-card-to'] .code-box-demo {
  background: #f5f5f5;
  overflow: hidden;
  padding: 24px;
}
.components-table-demo-control-bar {
  margin-bottom: 10px;
}
.components-table-demo-control-bar .ant-form-item {
  margin-right: 16px;
  margin-bottom: 8px;
}
[id^='components-message-demo-other'] .ant-btn {
  margin-right: 8px;
}
[id^='components-progress-demo'] .ant-progress-circle-wrap,
[id^='components-progress-demo'] .ant-progress-line-wrap {
  margin-right: 8px;
  margin-bottom: 5px;
}
[id^='components-popconfirm-demo-placement'] .ant-btn {
  width: 70px;
  text-align: center;
  padding: 0;
}

[id^='components-alert-demo'] .ant-alert {
  margin-bottom: 16px;
}

[id^='components-badge-demo'] .ant-badge:not(.ant-badge-not-a-wrapper) {
  margin-right: 20px;
}
[id^='components-badge-demo'] .head-example {
  width: 42px;
  height: 42px;
  border-radius: 4px;
  background: #e6e6e6;
  display: inline-block;
  vertical-align: middle;
}

[id^='components-carousel-demo'] .ant-carousel .slick-slide {
  text-align: center;
  height: 160px;
  line-height: 160px;
  background: #364d79;
  overflow: hidden;
}

[id^='components-carousel-demo'] .ant-carousel .slick-slide h3 {
  color: #fff;
}

[id^='components-progress-demo'] .ant-progress-circle-wrap,
[id^='components-progress-demo'] .ant-progress-line-wrap,
[id^='components-progress-demo'] .ant-progress-circle {
  margin-right: 8px;
  margin-bottom: 5px;
}

[id^='components-datepicker-demo'] .ant-calendar-picker,
[id^='components-timepicker-demo'] .ant-time-picker,
[id^='components-input-demo'] .ant-input-number,
[id^='components-input-demo'] .ant-select {
  margin-bottom: 16px;
  margin-right: 16px;
}

/* Scrollbar */
/* width */
::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #E4E4E4;
}
/* Handle */
::-webkit-scrollbar-THUMB {
  background: #61A50E;
  border-radius: 4px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #7AB32C;
}

/* Layout */
#root {
  .ant-layout {
    &.ant-layout-has-sider {
      flex-wrap: nowrap;
      justify-content: stretch;
    }

    .ant-btn-icon-only {
      line-height: 0;
    }

    .main-container {
      box-sizing: border-box;
      flex: 1;
      height: 100%;
      justify-content: stretch;
      padding: 20px;

      > .ant-card {
        display: flex;
        flex: 1;
        justify-content: stretch;
        height: 100%;
        overflow: hidden;
        width: 100%;

        > .ant-card-body {
          height: 100%;
          overflow-x: hidden;
          overflow-y: auto;
          width: 100%;
        }
      }
    }
  }
}

/* Power by */
.power-by {
  color: #FFF;
  font-size: 8px;
  margin-top: 10px;
  opacity: 0.2;
  text-align: center;

  .version {
    margin-top: 8px;
  }

  a {
    color: #FFF;
    line-height: 0;

    span, img {
      display: inline-block;
      float: none;
      line-height: normal;
      vertical-align: middle;
    }

    img {
      filter: brightness(0) invert(1);
      height: 18px;
      margin-left: 7px;
      width: auto;
    }
  }
}

/* Modal */
.ant-modal-root {
  .ant-btn-icon-only {
    line-height: 0;
  }
}

.assignment {
  min-height: 400px;
  .assignments-content {
    .employee {
      margin-bottom: 20px;
      .employee-name {
        font-size: 18px;
      }
    }
  }
}

.bg-menu {
  background-color: @menu-dark-bg;
  height: 10%;
}

/* Breadcrumb */
.ant-breadcrumb {
  .ant-breadcrumb-link {
    .ant-btn {
      padding-left: 0;
    }
  }
}

/* Empty label */
.ant-form {
  .empty-label {
    .ant-form-item-label {
      label:after {
        color: #FFF;
      }
    }
  }

  .ant-input-number {
    width: 100%;
  }
}

/* Loading */
.ant-btn {
  &.ant-btn-loading {
    .ant-btn-loading-icon {
      line-height: 1.5715;
      margin-right: 8px;

      > span.anticon-loading {
        padding: 0;
      }
    }
  }
}