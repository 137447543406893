.login-screen {
  height: 100vh;
  width: 100vw;
  .mh-page {
    height: 100vh;
    .wrapper {
      max-width: 800px;
      min-height: 400px;
      padding: 20px;
      z-index: 2;
      min-width: 600px;
      border-radius: 20px !important;
      @media only screen and (max-width: 1024px) {
        width: 90%;
        margin: auto;
        max-width: 100vw;
        min-width: 0px;
      }
    }
  }
  .forgot {
    cursor: pointer;
    margin-bottom: 10px;
  }
}
