@import '../Themes/default.less';

@import './app.less';
@import './global.less';
@import './Animation/clear.less';

@import './Screens/login.less';
@import './Screens/dashboard.less';
@import './Screens/home.less';
@import './Screens/import.less';

.file {
  margin: 10px;
  height: 65vh;
  overflow: auto;

  .ant-list-items {

    .ant-list-item {
      padding: 8px 0;

      .ant-list-item-meta {
        align-items: center;

        .ant-list-item-meta-avatar {
          margin-right: 10px;
        }

        .ant-list-item-meta-content {
          .ant-list-item-meta-title {
            margin-bottom: 0;
          }

          .ant-list-item-meta-description {
            font-size: 12px;
          }
        }
      }
    }

    .back-element {
      .ant-list-item-meta-content {
        h4 {
          color: #7AB32B;
        }
      }
    }

    .folder-element {
      .ant-list-item-meta {
        .ant-list-item-meta-content {
          h4 {
            font-weight: bold;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

@primary-color: #60A50D;@info-color: #CDDA01;@success-color: #52c41a;@processing-color: #037731;@error-color: #f5222d;@highlight-color: #f5222d;@warning-color: #faad14;@normal-color: #d9d9d9;@background-color: #f7f7f9;@text-color: rgba(0, 0, 0, .65);@text-color-secondary: rgba(0,0,0,.45);@dark-color: rgb(51,51,51);@border-radius-base: 4px;@border-radius-sm: 4px;@border-color-base: hsv(0, 0, 85%);@border-color-split: rgba(0, 0, 0, 0.05);@menu-inline-toplevel-item-height: 36px;@menu-item-height: 36px;@layout-header-background: #fff;@layout-header-height: 60px;@card-padding-base: 16px;@card-padding-wider: 16px;@menu-dark-bg: rgb(51,51,51);@menu-dark-submenu-bg: rgb(51,51,51);@code-family: "Anonymous Pro", "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace;@font-family: "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;