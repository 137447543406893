.home-screen {
  .card-head {
    margin-bottom: 20px;

    .menu {
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
    }
  }
}
.card-bg {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background: rgb(29, 89, 19);
  background: linear-gradient(
    90deg,
    rgba(29, 89, 19, 1) 0%,
    rgba(48, 129, 34, 1) 15%,
    rgba(63, 163, 47, 1) 30%,
    rgba(89, 195, 61, 1) 45%,
    rgba(92, 198, 64, 1) 60%,
    rgba(129, 208, 80, 1) 75%,
    rgba(163, 217, 97, 1) 90%,
    rgba(73, 195, 58, 1) 100%
  );
  color: #fff;
  display: flex;
  height: 100px;
  padding: 10px;
  justify-content: space-between;
  .flex-tw {
    display: flex;
    align-items: center;
    h3,
    h4 {
      color: #fff;
      line-height: 15px;
    }
    .ant-avatar {
      background-color: #bf3b2c;
      color: #fff;
      margin-right: 20px;
    }
  }
  @media only screen and (max-width: 1024px) {
    background: rgb(29, 89, 19);
    background: linear-gradient(
      90deg,
      rgba(29, 89, 19, 1) 0%,
      rgba(163, 217, 97, 1) 80%
    );
  }
}
